import { useSelector } from "react-redux";
import { api } from "./config/axiosConfig.js";
import { defineCancelApiObject } from "./config/axiosUtils.js";
import { store } from "../redux/Store.ts";
import { errorResponseHandler, responseHandler } from "../Utils/handler.ts";

export const CommonAPI = {
  /////////////////////////
  // EVENTS
  ////////////////////////

  getChat: async (recieverId, cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/chat/retrieve/" + recieverId,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },

  sendChat: async (recieverId, data, cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/chat/send/" + recieverId,
        method: "POST",
        data: data,
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      console.log(response);
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },

  postNotes: async (data, cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/notes",
        method: "POST",
        data: data,
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      console.log(response);
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },

  getNotes: async (params, cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/notes",
        method: "GET",
        params: params,
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      console.log(response);
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },

  getNotifications: async (cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/notifications",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },

  markAllNotificationsAsRead: async (cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "notifications/mark-all-as-read",
        method: "PATCH",
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },

  // Meeting
  PostCreateMeeting: async (id, data, cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/meeting-requests/" + id,
        method: "POST",
        data: data,
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      console.log(response);
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },
  CheckMeetingAcceptAllowed: async (meetId, params, cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/meeting-requests/" + meetId,
        method: "GET",
        params: params,
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      console.log(response);
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },

  CheckMeetingChangeAllowed: async (
    meetId,
    date,
    time,
    params,
    cancel = false
  ) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: `/meeting-requests/${meetId}?date=${date}&time=${time}`,
        method: "GET",
        params: params,
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      console.log(response);
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },

  GetMeeting: async (params, cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/meeting-requests",
        method: "GET",
        params: params,
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      console.log(response);
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },
  UpdateMeeting: async (id, data, cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/meeting-requests/" + id,
        method: "PATCH",
        data: data,
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      console.log(response);
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },
};

const cancelApiObject = defineCancelApiObject(CommonAPI);
