import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import Header from "../../Components/Common/Header";
import { FiCopy } from "react-icons/fi";

type Props = {};

const SubscriptionPurchase = (props: Props) => {
  return (
    <Box flexGrow={1} p={6} w={"100%"}>
      <Header backButton>Purchase Order Confirmation</Header>
      <Text
        textAlign={"center"}
        fontSize={"16px"}
        fontWeight={"bold"}
        mt={10}
        color={"black"}
      >
        We have received your request for purchase of 10 Connects.
      </Text>
      <Text
        textAlign={"center"}
        fontSize={"16px"}
        fontWeight={"bold"}
        mt={"54px"}
        color={"black"}
      >
        The reference number for <br />
        this request is
      </Text>
      <Flex justifyContent={"center"} alignItems={"center"} gap={2}>
        <Text
          textAlign={"center"}
          fontSize={"20px"}
          fontWeight={"bold"}
          color={"secondary.orange"}
        >
          SS1234567CPC
        </Text>
        <FiCopy color="#F5793D" />
      </Flex>
      <Text
        textAlign={"center"}
        fontSize={"16px"}
        fontWeight={"bold"}
        mt={"130px"}
        color={"black"}
      >
        Our sales executive will get in touch with you shortly.
      </Text>
      <Text
        textAlign={"center"}
        fontSize={"12px"}
        fontWeight={"medium"}
        mt={2}
        color={"black"}
      >
        For any queries, email{" "}
        <a
          style={{ color: "#F5793D" }}
          href="mailto:connect@prontocommerce.com"
        >
          connect@prontocommerce.com
        </a>{" "}
        stating the reference number in the subject line.
      </Text>
    </Box>
  );
};

export default SubscriptionPurchase;
