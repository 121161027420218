import NotificationIllustration from './no_notification.svg';
import Annoucements from './announcements.png';
import Stats from './stats.png';
import rfpDoc from './rfp.png';
import Logo from './connect-logo.jpeg';
import schedule from './schedule.png';
import headerElements from './header_elements.png'

export const Images = {
    NotificationIllustration,
    Annoucements,
    Stats,
    rfpDoc,
    Logo,
    schedule,
    headerElements
}