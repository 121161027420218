import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../../Components/Common/Header";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import FAQModal from "../../Components/Modals/FAQModal";

type Props = {};

const Rewards = (props: Props) => {
  const [isModal, setIsModal] = useState(false);

  const handleOpenModal = () => setIsModal(true);
  const handleCloseModal = () => setIsModal(false);

  const navigate = useNavigate();
  return (
    <Box p={6} display={"flex"} flexDir={"column"}>
      <Modal
        footer={null}
        title={null}
        open={isModal}
        onCancel={handleCloseModal}
        closeIcon={null}
        onOk={handleCloseModal}
      >
        <FAQModal handleCloseModal={handleCloseModal} />
      </Modal>
      <Header backButton>Rewards</Header>
      <Box mt={5} py={7} bg={"#22121927"} rounded={"18px"}>
        <Text
          fontSize={"15px"}
          color={"text.black"}
          textAlign={"center"}
          fontWeight={"semibold"}
        >
          My Reward Points
        </Text>
        <Text
          fontSize={"48px"}
          color={"text.black"}
          textAlign={"center"}
          fontWeight={"semibold"}
        >
          500
        </Text>
      </Box>
      <Box mt={5}>
        <Button
          w={"100%"}
          h={"36px"}
          textAlign={"center"}
          bg={"secondary.light"}
          color={"secondary.main"}
          onClick={() => {
            navigate("/visitor/rewards-redeem");
          }}
        >
          Redeem
        </Button>
        <Text fontSize={"10px"} color={"text.black"} mt={1}>
          By clicking “Redeem” you agree to these{" "}
          <span style={{ fontWeight: "800", cursor: "pointer" }}>T&C</span>
        </Text>
      </Box>
      <Box mt={8}>
        <Text fontSize={"15px"} fontWeight={"semibold"} color={"text.black"}>
          Latest Statements
        </Text>
        <VStack>
          {[0, 0, 0].map(() => (
            <Box
              w={"100%"}
              bg={"white"}
              p={3}
              rounded={"18px"}
              mt={3}
              px={5}
              py={4}
              display={"flex"}
              gap={4}
              justifyContent={"space-between"}
              alignItems={"start"}
              shadow={"md"}
              minH={"95px"}
            >
              <Box>
                <Text
                  fontSize={"15px"}
                  fontWeight={"semibold"}
                  color={"gray.600"}
                >
                  12 Dec 2023
                </Text>
                <Text fontSize={"12px"} mt={2} maxW={"200px"}>
                  Rewards points Redeemed FAS788FUS345
                </Text>
              </Box>
              <Text fontSize={"14px"} color={"red"}>
                -100
              </Text>
            </Box>
          ))}
        </VStack>
      </Box>
      <HStack justifySelf={"end"} mt={5}>
        <Button
          flex={1}
          size={"md"}
          p={0}
          bg={"rgba(40,105,254,0.1)"}
          color={"#2869FE"}
          onClick={handleOpenModal}
        >
          FAQ
        </Button>
        <Button
          flex={1}
          size={"md"}
          p={0}
          bg={"rgba(40,105,254,0.1)"}
          color={"#2869FE"}
          onClick={() => {
            navigate("/visitor/rewards-statement");
          }}
        >
          Statement
        </Button>
      </HStack>
    </Box>
  );
};

export default Rewards;
