import { useSelector } from "react-redux";
import { api } from "./config/axiosConfig.js";
import { defineCancelApiObject } from "./config/axiosUtils.js";
import { store } from "../redux/Store.ts";
import { errorResponseHandler, responseHandler } from "../Utils/handler.ts";

export const VisitorAPI = {
  /////////////////////////
  // EVENTS
  ////////////////////////

  getEvents: async (params, cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/visitors/events",
        method: "GET",
        params: params,
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },

  getVisitorCompanies: async (eventId, params, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/" + eventId + "/companies",
      method: "GET",
      params: params,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  getExhibitors: async (params, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "visitors/exhibitor/companies",
      method: "GET",
      params: params,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  getVisitorProfile: async (cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "visitors",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  updateVisitorProfile: async (data, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "visitors",
      method: "PUT",
      data: data,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  PostRelevanceExhibitor: async (data, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "pre-qualify/exhibitor",
      method: "POST",
      data: data,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },
  GetDashboard: async (cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "visitors/dashboard",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },
  getInterestedTags: async (params, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "visitors/interested-tags",
      params,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },
};

const cancelApiObject = defineCancelApiObject(VisitorAPI);
