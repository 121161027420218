import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  Image,
  HStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  MdOutlineLocationOn,
  MdOutlineCalendarMonth,
  MdOutlineHandshake,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { errorHandler } from "../../Utils/handler";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { BsBoxArrowUpRight } from "react-icons/bs";
import {
  ExhibitorScreens,
  MEETING_REQUEST_STATUS,
  MeetingsField,
  RelevanceFieldsNew,
  VisitorScreens,
  relevanceFields,
} from "../../Constants/data";
import { Images } from "../../Assets/images";
import Jumbotron from "../../Components/Common/Jumbotron";
import CountDisplay from "../../Components/Common/CountDisplay";
import { CommonAPI } from "../../Apis/commonApi";
import MeetingListItem from "../../Components/Common/MeetingListItem";
import EventListItem from "../../Components/Common/EventListItem";
import actions from "../../redux/Actions";
import { VisitorAPI } from "../../Apis/visitorAPI";

type Props = {};

const Dashboard = (props: Props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [data, setData] = useState<any>();

  const user = useSelector((state: any) => state.user);

  const visitorMenu = useSelector((state: any) => state.visitorMenu);

  const [isScreenWidthSufficient] = useMediaQuery(["(min-width: 490px)"]);

  const [profile, setProfile] = useState<any>();

  const { success, error, info } = useToastWrapper();

  const [meetingCounts, setMeetingCounts] = useState<any>({});

  const getData = async () => {
    try {
      const [res, res2, res3] = await Promise.all([
        VisitorAPI.GetDashboard(),
        VisitorAPI.getVisitorProfile(),
        CommonAPI.GetMeeting(undefined),
      ]);
      if (res) {
        setData(res.data.data);
      }
      if (res2) {
        setProfile(res2.data.data);
      }
      if (res3) {
        setMeetingCounts(res3.data.meetingCounts);
      }
    } catch (err) {
      error(errorHandler(err));
    }
  };

  useEffect(() => {
    getData();
    dispatch(
      actions.visitorMenu.set({
        activeMenu: VisitorScreens.HOME,
      })
    );
  }, []);

  const {
    notification: { unreadNotifications },
  } = useSelector((state: any) => state);

  const disableUpcomingMeetingButton = (
    proposedDateStr: string,
    proposedTime: string
  ) => {
    const today = new Date();
    const proposedDate = new Date(proposedDateStr);

    if (
      today.getDay() === proposedDate.getDay() &&
      today.getMonth() === proposedDate.getMonth() &&
      today.getFullYear() === proposedDate.getFullYear() &&
      today.getHours() >= parseInt(proposedTime.split(":")[0]) &&
      today.getMinutes() >= parseInt(proposedTime.split(":")[1])
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Box w={"100%"} p={"0"}>
      <Jumbotron
        personName={profile?.visitorPersonName}
        companyName={profile?.visitorCompanyName}
        unreadNotifications={unreadNotifications}
        role="Visitor"
        navigateUrl="/visitor/notifications"
      />
      <Box
        w={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        px={"30px"}
        backgroundColor={"body.bg"}
      >
        <Box w={"100%"} mt={7}>
          <Text fontSize={"lg"} fontWeight={"semibold"}>
            Recent Events
          </Text>
          <Box
            mt={"20px"}
            p={"20px"}
            _hover={{
              shadow: "md",
            }}
            border={"1px solid #D2D2D2"}
            borderRadius={"15px"}
            shadow={"sm"}
            backgroundColor={"body.cardBg"}
          >
            {data?.recentOrOngoingEventWithExhibitors?.eventData ? (
              <Box m={0} p={0}>
                {isScreenWidthSufficient ? (
                  <HStack justifyContent={"space-between"} mb={"8px"}>
                    <Text fontSize={"md"} fontWeight={"semibold"}>
                      {
                        data?.recentOrOngoingEventWithExhibitors?.eventData
                          ?.eventName
                      }
                    </Text>

                    <HStack gap={"0 .25rem"}>
                      <MdOutlineCalendarMonth
                        color={"icon.main"}
                        size={18}
                        fontWeight={"light"}
                      />
                      <Text fontSize={"sm"} fontWeight={"light"}>
                        {moment(
                          data?.recentOrOngoingEventWithExhibitors?.eventData
                            ?.startDate
                        )?.format("DD MMM")}{" "}
                        -{" "}
                        {moment(
                          data?.recentOrOngoingEventWithExhibitors?.eventData
                            ?.endDate
                        )?.format("DD MMM YYYY")}
                      </Text>
                    </HStack>
                  </HStack>
                ) : (
                  <VStack alignItems={"flex-start"}>
                    <Text fontSize={"md"} fontWeight={"semibold"} mb={"8px"}>
                      {
                        data?.recentOrOngoingEventWithExhibitors?.eventData
                          ?.eventName
                      }
                    </Text>

                    <HStack gap={"0 .25rem"} mb={"8px"}>
                      <MdOutlineCalendarMonth
                        color={"icon.main"}
                        size={18}
                        fontWeight={"light"}
                      />
                      <Text fontSize={"sm"} fontWeight={"light"}>
                        {moment(
                          data?.recentOrOngoingEventWithExhibitors?.eventData
                            ?.startDate
                        )?.format("DD MMM")}{" "}
                        -{" "}
                        {moment(
                          data?.recentOrOngoingEventWithExhibitors?.eventData
                            ?.endDate
                        )?.format("DD MMM YYYY")}
                      </Text>
                    </HStack>
                  </VStack>
                )}
                <HStack gap={"0 .25rem"} mb={"8px"}>
                  <MdOutlineLocationOn
                    color={"icon.main"}
                    size={15}
                    fontWeight={"light"}
                  />
                  <Text fontSize={"sm"} fontWeight={"light"}>
                    {
                      data?.recentOrOngoingEventWithExhibitors?.eventData
                        ?.eventVenue
                    }
                    ,{" "}
                    {
                      data?.recentOrOngoingEventWithExhibitors?.eventData
                        ?.eventCity
                    }
                  </Text>
                </HStack>
                <HStack gap={"0 .25rem"} mb={"8px"}>
                  <MdOutlineHandshake
                    color={"icon.main"}
                    size={15}
                    fontWeight={"light"}
                  />
                  <Text fontSize={"sm"} fontWeight={"light"}>
                    {
                      data?.recentOrOngoingEventWithExhibitors?.eventData
                        ?.organizerId?.organizerCompanyName
                    }
                  </Text>
                </HStack>
                <Flex justifyContent={"space-between"} mt={5}>
                  <Text fontSize={"md"} fontWeight={"medium"}>
                    Exhibitors
                  </Text>
                </Flex>
                <Flex mt={4} justifyContent={"space-between"} gap={"0 .25rem"}>
                  <CountDisplay
                    width="25%"
                    title={RelevanceFieldsNew.RELEVANT.title}
                    count={
                      data?.recentOrOngoingEventWithExhibitors
                        ?.relevantExhibitorsCount
                    }
                    color={"visitors.relevant"}
                  />
                  <CountDisplay
                    width="25%"
                    title={RelevanceFieldsNew.REVIEW.title}
                    count={
                      data?.recentOrOngoingEventWithExhibitors
                        ?.underResearchExhibitorsCount
                    }
                    color={"visitors.review"}
                  />

                  <CountDisplay
                    width="25%"
                    title={RelevanceFieldsNew.EXISTING.title}
                    count={
                      data?.recentOrOngoingEventWithExhibitors
                        ?.existingRelationshipExhibitorsCount
                    }
                    color={"visitors.existing"}
                  />

                  <CountDisplay
                    width="25%"
                    title={RelevanceFieldsNew.NOTRELEVANT.title}
                    count={
                      data?.recentOrOngoingEventWithExhibitors
                        ?.notRelevantExhibitorsCount
                    }
                    color={"visitors.notRelevant"}
                  />
                </Flex>
              </Box>
            ) : (
              <Box>
                <Text fontSize={"md"} fontWeight={500}>
                  No Recent Events
                </Text>
              </Box>
            )}

            <HStack justifyContent={"center"}>
              <Button
                onClick={() => {
                  navigate("/visitor/list");
                }}
                colorScheme={"gray"}
                variant={"outline"}
                // w={"30%"}
                color={"secondary.orange"}
                _hover={{ background: "secondary.light" }}
                mt={"20px"}
                h={"40px"}
                minH={"40px"}
                px={2}
              >
                View Exhibitors List
              </Button>
            </HStack>
            <Flex justifyContent={"space-between"} mt={5}>
              <Text fontSize={"md"} fontWeight={"medium"}>
                Meetings
              </Text>
            </Flex>
            {/* {meetingCounts && meetingCounts[MEETING_REQUEST_STATUS.CONFIRM] && <Flex mt={4} justifyContent={"space-between"} gap={"0 .25rem"}> */}
            <Flex mt={4} justifyContent={"space-between"} gap={"0 .25rem"}>
              <CountDisplay
                width="25%"
                title={MeetingsField.CONFIRMED.title}
                count={
                  meetingCounts[MEETING_REQUEST_STATUS.CONFIRM]
                    ? meetingCounts[MEETING_REQUEST_STATUS.CONFIRM]
                    : 0
                }
                color={"meetings.confirmed"}
              />
              <CountDisplay
                width="25%"
                title={MeetingsField.RECEIVED.title}
                count={
                  meetingCounts[MEETING_REQUEST_STATUS.REQUEST_RECEIVED]
                    ? meetingCounts[MEETING_REQUEST_STATUS.REQUEST_RECEIVED]
                    : 0
                }
                color={"meetings.received"}
              />

              <CountDisplay
                width="25%"
                title={MeetingsField.SENT.title}
                count={
                  meetingCounts[MEETING_REQUEST_STATUS.RESPONSE_AWAITED]
                    ? meetingCounts[MEETING_REQUEST_STATUS.RESPONSE_AWAITED]
                    : 0
                }
                color={"meetings.sent"}
              />

              <CountDisplay
                width="25%"
                title={MeetingsField.COMPLETED.title}
                count={
                  meetingCounts[MEETING_REQUEST_STATUS.CLOSED]
                    ? meetingCounts[MEETING_REQUEST_STATUS.CLOSED]
                    : 0
                }
                color={"meetings.completed"}
              />
            </Flex>
            {/* } */}
            <HStack justifyContent={"center"}>
              <Button
                onClick={() => {
                  navigate("/visitor/calendar");
                }}
                colorScheme={"gray"}
                variant={"outline"}
                // w={"30%"}
                color={"secondary.orange"}
                _hover={{ background: "secondary.light" }}
                mt={"20px"}
                h={"40px"}
                minH={"40px"}
                px={2}
              >
                View All Meetings
              </Button>
            </HStack>
            <Text mt={"40px"} fontSize={"md"} fontWeight={"medium"}>
              Upcoming Meeting
            </Text>
            {data?.upcomingMeeting ? (
              <Box mt={"22px"} mb={"30px"}>
                <MeetingListItem
                  companyName={
                    user.user == data?.upcomingMeeting?.requesterId
                      ? data?.upcomingMeeting?.responderCompany
                      : data?.upcomingMeeting?.requesterCompany
                  }
                  proposedDate={moment(
                    data?.upcomingMeeting?.proposedDate
                  )?.format("DD/MMM/YYYY")}
                  proposedTime={data?.upcomingMeeting?.proposedTime}
                  disableButton={disableUpcomingMeetingButton(
                    data?.upcomingMeeting?.proposedDate,
                    data?.upcomingMeeting?.proposedTime
                  )}
                  joinButtonClick={() => {
                    // info("Meeting link will be activated on that date!");
                    if (user.role == "Exhibitor") {
                      const resMobile =
                        user.user ===
                        data?.upcomingMeeting?.responderDetails.userId
                          ? data?.upcomingMeeting?.requesterDetails
                              .visitorPhoneNumber
                          : data?.upcomingMeeting?.responderDetails
                              .visitorPhoneNumber;

                      window.location.href = "tel:" + resMobile;
                    } else if (user.role == "Visitor") {
                      const resMobile =
                        user.user ===
                        data?.upcomingMeeting?.requesterDetails.userId
                          ? data?.upcomingMeeting?.responderDetails
                              .personPhoneNumber
                          : data?.upcomingMeeting?.requesterDetails
                              .personPhoneNumber;

                      window.location.href = "tel:" + resMobile;
                    }
                  }}
                />
              </Box>
            ) : (
              <Box>
                {/* <Text flex={1} fontSize={"sm"} fontWeight={500}>
                                    No confirmed meetings yet. Please see all meeting list to review
                                    pending requests.
                                </Text> */}
              </Box>
            )}
          </Box>
        </Box>
        <Box w={"100%"} mt={6} mb={"30px"}>
          <Text fontSize={"lg"} fontWeight={"semibold"}>
            Upcoming Event
          </Text>
          {data?.upcomingEvent?.eventData ? (
            <EventListItem
              eventName={data?.upcomingEvent?.eventData?.eventName}
              startDate={data?.upcomingEvent?.eventData?.startDate}
              endDate={data?.upcomingEvent?.eventData?.endDate}
              eventData={{
                eventVenue: data?.upcomingEvent?.eventData?.eventVenue,
                eventCity: data?.upcomingEvent?.eventData?.eventCity,
              }}
              orgCompanyName={
                data?.upcomingEvent?.eventData?.organizerId
                  ?.organizerCompanyName
              }
            />
          ) : (
            <Box
              mt={"10px"}
              p={"30px"}
              _hover={{
                shadow: "md",
              }}
              border={"1px solid #E2E8F0"}
              borderRadius={"15px"}
              shadow={"sm"}
              cursor={"pointer"}
            >
              <Text fontSize={"md"} fontWeight={500}>
                No Upcoming Events
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
