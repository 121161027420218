import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { Modal, Select } from "antd";

//// Icons
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";

import { TfiWorld } from "react-icons/tfi";
import { BiFilter, BiSearch, BiSort } from "react-icons/bi";
import { useNavigate, useSearchParams } from "react-router-dom";
import SortModal from "../../Components/Modals/SortModal";
import { errorHandler } from "../../Utils/handler";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import {
  EventInterface,
  VisitorInterface,
} from "../../Interface/CommonInterface";
import Loader from "../../Components/Loader/Loader";
import RelevanceModal from "../../Components/Modals/RelevanceModal";
import {
  ExhibitorScreens,
  MEETING_REQUEST_STATUS,
  RelevanceFieldsNew,
  relevanceFields,
} from "../../Constants/data";
import FilterModal from "../../Components/Modals/FilterModal";
import { get } from "http";
import moment from "moment";
import actions from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import FilterToggleButton from "../../Components/Common/FilterToggleButton";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import CreateMeetModal from "../../Components/Modals/CreateMeetModal";
import NotesModal from "../../Components/Modals/NotesModal";
import { MdCalendarToday, MdChat } from "react-icons/md";
import { CommonAPI } from "../../Apis/commonApi";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal";
import { m } from "framer-motion";

interface inputType {
  event: string;
  company: string;
}

const compareTimeDifference = (time1: string, time2: string) => {
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);

  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;

  const difference = Math.abs(totalMinutes1 - totalMinutes2);

  if (difference <= 30) {
    return false;
  } else {
    return true;
  }
};

const ExhibitorHome = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user);

  const filterToggleRef = React.useRef<HTMLButtonElement>();

  const isInitialRender = useRef(true);

  const [relevanceData, setRelevanceData] = useState({
    prequalified_company_name: "",
    prequalified_user_id: [],
    event_id: "",
    status: "Under_Research",
  });

  // Sort Modal
  const [sortModal, setSortModal] = useState(false);
  const handleOpenSortModal = () => setSortModal(true);
  const handleCloseSortModal = () => setSortModal(false);

  // Filter Modal
  const [filterModal, setFilterModal] = useState(false);
  const handleOpenFilterModal = () => setFilterModal(true);
  const handleCloseFilterModal = () => setFilterModal(false);

  // Relevance Modal
  const [releModel, setReleModel] = useState(false);
  const handleOpenRelevanceModel = () => setReleModel(true);
  const handleCloseRelevanceModel = () => setReleModel(false);

  //Meet Modal
  const [meetModal, setMeetModal] = useState(false);
  const handleOpenMeetModal = () => setMeetModal(true);
  const handleCloseMeetModal = () => setMeetModal(false);

  // Notes modal
  const [notesModal, setNotesModal] = useState(false);
  const handleOpenNotesModal = () => setNotesModal(true);
  const handleCloseNotesModal = () => setNotesModal(false);

  //Sort Consts
  const [orderSort, setOrderSort] = useState("a-to-z");
  const [relevanceSort, setRelevanceSort] = useState("");

  const [search, setSearch] = useState("");

  const [email, setEmail] = useState("");

  const [resetSearch, setResetSearch] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    event: "",
    company: "",
    email: "",
  });

  const [newVisitor, setNewVisitor] = useState<any>({});

  const [meetingData, setMeetingData] = useState<any>([]);

  const [event, setEvent] = useState<EventInterface[]>([]);

  const [companies, setCompanies] = useState<any>([]);

  const [accordinActive, setAccordinActive] = useState("");

  const [exhibitorData, setExhibitorData] = useState<any>({});

  const [visitors, setVisitors] = useState<VisitorInterface[]>([]);

  const [meetResponder, setMeetResponder] = useState<any>();

  const [meetConfirm, setMeetConfirm] = useState(false);
  const handleOpenMeetConfirmModal = () => setMeetConfirm(true);
  const handleCloseMeetConfirmModal = () => {
    setMeetConfirm(false);
    setCurrentMeet("");
  };

  const [addNewVisitor, setAddNewVisitor] = useState(false);
  const handleOpenVisitorAddModal = () => setAddNewVisitor(true);
  const handleCloseVisitorAddModal = () => {
    setAddNewVisitor(false);
    setNewVisitor({});
  };

  const [currentMeet, setCurrentMeet] = useState("");

  const [userPlan, setUserPlan] = useState<any>({});

  const [notesData, setNotesData] = useState<any>({
    forUser: "",
    eventId: "",
    companyName: "",
  });

  const [details, setDetails] = useState({
    company: "",
    event: "",
  });

  const { success, error } = useToastWrapper();

  const isNewMeetingAllowed = (status: string) => {
    if (
      status === MEETING_REQUEST_STATUS.CANCELLED ||
      status === MEETING_REQUEST_STATUS.EXPIRED ||
      status === MEETING_REQUEST_STATUS.CLOSED
    ) {
      return true;
    }

    return false;
  };

  const getPlanDetails = async () => {
    try {
      const userId = user.user;
      const [planDetails] = await Promise.all([
        ExhibitorAPI.getEventExhibitorProfile(userId),
      ]);

      if (planDetails) {
        setUserPlan(planDetails?.data?.data);
      }
    } catch (err) {
      error("Unable to get subscription details!");
    }
  };

  const getEvents = async () => {
    try {
      setLoading(true);
      const [data, data2, data3] = await Promise.all([
        ExhibitorAPI.getEvents(),
        ExhibitorAPI.getExhibitorProfile(),
        CommonAPI.GetMeeting({
          status: undefined,
        }),
      ]);

      if (data?.success) {
        console.log("inside email data..", data);
        const arr2 = [...data.data.data].filter((s) =>
          moment(s.startDate).isBefore(moment())
        );
        const arr = [...arr2];
        setEvent(arr.reverse());
        setInputs({
          ...inputs,
          event: arr2[arr2.length - 1]?._id || "",
        });
      }
      if (data2) setExhibitorData(data2?.data?.data);

      if (data3) {
        const response = JSON.parse(JSON.stringify(data3?.data?.data));
        let meetingDataObj: any = {};
        console.log("response...", response);
        response.forEach((element: any) => {
          let temp = {
            _id: element._id,
            requesterId: element.requesterId,
            responderId: element.responderId,
            requesterStatus: element.requesterStatus,
            responderStatus: element.responderStatus,
          };

          meetingDataObj[element.responderCompany] = temp;
        });

        setMeetingData(meetingDataObj);
      }
    } catch (err: any) {
      const errMsg = errorHandler(err);

      error(errMsg);
    } finally {
      // setLoading(false);
    }
  };

  const resetFilters = () => {
    if (search !== "") {
      setResetSearch(!resetSearch);
    }
    setSearch("");
    setInputs({ ...inputs, event: "" });
    setOrderSort("a-to-z");
    if (orderSort === "z-to-a") {
      filterToggleRef.current?.click();
    }
    setRelevanceSort("");
  };

  const getVisitors = async (eventId: string, company: string) => {
    try {
      setLoading(true);

      const data = await ExhibitorAPI.getVisitors(eventId, {
        companyName: company,
      });

      if (data.success) {
        setVisitors(data?.data?.data);
      }
    } catch (err: any) {
      const errMsg = errorHandler(err);
      error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const visitorCompanyListItemClick = (
    eventId: string,
    companyName: string
  ) => {
    const obj = {
      company: "",
      event: "",
    };

    if (eventId) {
      obj.event = eventId;
    }

    if (companyName) {
      obj.company = companyName;
    }

    if (obj.event) {
      getVisitors(obj.event, obj?.company);
      setNotesData({
        ...notesData,
        eventId: obj.event,
      });
    }
    setDetails({ ...obj });
  };

  const getVisitorCompaniesV2 = async (
    eventId: string,
    o: string = orderSort
  ) => {
    try {
      setLoading(true);
      // console.log(
      //   "initial render inside visitor company..",
      //   isInitialRender.current
      // );
      const params: any = {};

      if (email) {
        params["email"] = email;
        let innerParams: any = {
          email,
          eventId,
        };
        const data = await ExhibitorAPI.checkEmailExists(innerParams);

        if (data.success) {
          let { message } = data.data;
          if (
            message.toLowerCase().includes("visitor found") &&
            data?.data?.data._id
          ) {
            setNewVisitor(data.data.data);
            handleOpenVisitorAddModal();
          } else if (
            message.toLowerCase().includes("visitor not found") &&
            Object.keys(data?.data?.data).length === 0
          ) {
            error("Couldn't find a visitor matching the mail");
          } else if (
            message.toLowerCase().includes("visitor already exists") &&
            Object.keys(data?.data?.data).length === 0
          ) {
            error("Visitor already present in your list!");
          }
        }
      }

      if (eventId) {
        params["eventId"] = eventId;
      }

      if (search) {
        params["search"] = search;
      }

      // if (email) {
      //   params["email"] = email;
      // }

      if (o) {
        params["filter"] = o;
      }

      if (relevanceSort) {
        params["categoryToFilter"] = relevanceSort;
      }

      const data = await ExhibitorAPI.getVisitorCompaniesV2(params);

      if (data.success) {
        console.log("visitor companies....", data.data);
        await setCompanies(data?.data?.data);
      }
    } catch (err: any) {
      const errMsg = errorHandler(err);
      error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const RelevanceFilterComponent = () => {
    return (
      <Select
        className="custom-select-noborder"
        style={{
          width: "100%",
          border: "0px",
          outline: "none",
          height: "100%",
          fontFamily: '"Inter Variable", sans-serif',
        }}
        dropdownStyle={{
          width: "auto",
          fontFamily: '"Inter Variable", sans-serif',
        }}
        onChange={(e) => {
          setRelevanceSort(e);
        }}
        // optionFilterProp="children"
        value={relevanceSort}
        dropdownRender={(menu) => (
          <div style={{ lineHeight: 0, margin: 0, padding: 0 }}>{menu}</div>
        )}
        // options={[
        //   {
        //     label: "All",
        //     value: "",
        //   },
        //   {
        //     label: (
        //       <Box
        //         height={18}
        //         width={18}
        //         lineHeight={0}
        //         margin={0}
        //         padding={0}
        //         borderRadius={"2px"}
        //         background={RelevanceFieldsNew.RELEVANT.colorHash}
        //       ></Box>
        //     ),
        //     value: RelevanceFieldsNew.RELEVANT.status,
        //   },
        //   {
        //     label: (
        //       <Box
        //         height={18}
        //         width={18}
        //         borderRadius={"2px"}
        //         background={RelevanceFieldsNew.EXISTING.colorHash}
        //       ></Box>
        //     ),
        //     value: RelevanceFieldsNew.EXISTING.status,
        //   },
        //   {
        //     label: (
        //       <Box
        //         height={18}
        //         width={18}
        //         borderRadius={"2px"}
        //         background={RelevanceFieldsNew.REVIEW.colorHash}
        //       ></Box>
        //     ),
        //     value: RelevanceFieldsNew.REVIEW.status,
        //   },
        //   {
        //     label: (
        //       <Box
        //         height={18}
        //         width={18}
        //         borderRadius={"2px"}
        //         background={RelevanceFieldsNew.NOTRELEVANT.colorHash}
        //       ></Box>
        //     ),
        //     value: RelevanceFieldsNew.NOTRELEVANT.status,
        //   },
        // ]}
        options={[
          {
            label: "All",
            value: "",
          },
          {
            label: RelevanceFieldsNew.RELEVANT.title,
            value: RelevanceFieldsNew.RELEVANT.status,
          },
          {
            label: RelevanceFieldsNew.EXISTING.title,
            value: RelevanceFieldsNew.EXISTING.status,
          },
          {
            label: RelevanceFieldsNew.REVIEW.title,
            value: RelevanceFieldsNew.REVIEW.status,
          },
          {
            label: RelevanceFieldsNew.NOTRELEVANT.title,
            value: RelevanceFieldsNew.NOTRELEVANT.status,
          },
        ]}
      />
    );
  };

  const addVisitor = async () => {
    try {
      const res = await ExhibitorAPI.addNewVisitorToList({
        eventId: inputs.event,
        visitorId: newVisitor._id,
      });
      if (res.success) {
        setNewVisitor({});
        setEmail("");
        handleCloseVisitorAddModal();
        getVisitorCompaniesV2(inputs?.event);
        success("Visitor added successfully!");
      }
    } catch (err: any) {
      error(err?.message);
    }
  };

  const LoadStates = async () => {
    const oSort = searchParams.get("o");
    const rSort = searchParams.get("r");
    // const sSort = searchParams.get('s');

    await setOrderSort(oSort || "a-to-z");
    await setRelevanceSort(rSort || "");

    console.log(isInitialRender.current);
    if (oSort && rSort) getVisitorCompaniesV2(inputs?.event, oSort || "");
    console.log(isInitialRender.current);
  };

  useEffect(() => {
    const oSort = searchParams.get("o");
    const rSort = searchParams.get("r");
    // const sSort = searchParams.get('s');

    setOrderSort(oSort || "a-to-z");
    setRelevanceSort(rSort || "");

    console.log("isInitialRender.current...", isInitialRender.current);
    //  if (oSort) getVisitorCompaniesV2(inputs?.event, oSort || "");
    getEvents();
    getPlanDetails();
    dispatch(
      actions.exhibitorMenu.set({
        activeMenu: ExhibitorScreens.DETAILS,
      })
    );

    isInitialRender.current = false;
    console.log(isInitialRender.current);
  }, []);

  useEffect(() => {
    setSearchParams({ o: orderSort });
  }, [orderSort]);

  // useEffect(() => {
  //   getEvents();
  //   dispatch(
  //     actions.exhibitorMenu.set({
  //       activeMenu: ExhibitorScreens.DETAILS,
  //     })
  //   );
  // }, []);

  useEffect(() => {
    if (isInitialRender.current == false && inputs?.event) {
      console.log("logging after initial render");

      console.log(isInitialRender.current);

      getVisitorCompaniesV2(inputs?.event);
    }
  }, [inputs?.event, orderSort, relevanceSort, resetSearch]);

  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={"100vh"}
      maxH={"100vh"}
      px={"30px"}
      bg={"body.bg"}
    >
      {loading && <Loader />}
      {/* Modal for visitor add confirm */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={addNewVisitor}
        onOk={handleCloseVisitorAddModal}
        onCancel={handleCloseVisitorAddModal}
      >
        <ConfirmationModal
          handleCloseModal={handleCloseVisitorAddModal}
          handleSubmit={() => {
            addVisitor();
          }}
          title={"Visitor found with email"}
          message={
            "Visitor matching this email address found. Add to your list ?"
          }
          confirmBtnText={"Yes, Add"}
          rejectButtonText={"No"}
        />
      </Modal>
      {/* Modal for meet confirm */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={meetConfirm}
        onOk={handleCloseMeetConfirmModal}
        onCancel={handleCloseMeetConfirmModal}
      >
        <ConfirmationModal
          handleCloseModal={handleCloseMeetConfirmModal}
          handleSubmit={() => {
            navigate("/exhibitor/calendar?id=" + currentMeet);
          }}
          title={"Open meeting already exists"}
          message={"Please view & edit the same.  View details now ?"}
          confirmBtnText={"Yes, View"}
          rejectButtonText={"No"}
        />
      </Modal>
      {/* Modal for Meet */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={meetModal}
        onOk={handleCloseMeetModal}
        onCancel={handleCloseMeetModal}
      >
        <CreateMeetModal
          meetRequesterPhone={exhibitorData.personPhoneNumber}
          responderId={meetResponder?.userId}
          eventId={details.event}
          company={meetResponder?.visitorPersonName}
          visitorCompanyName={meetResponder?.visitorCompanyName}
          designation={meetResponder?.visitorDesignation}
          handleCloseModal={handleCloseMeetModal}
        />
      </Modal>
      {/* Modal for notes*/}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={notesModal}
        onOk={handleCloseNotesModal}
        onCancel={handleCloseNotesModal}
      >
        <NotesModal
          eventId={notesData.eventId}
          forUser={notesData.forUser}
          companyName={notesData.companyName}
          handleCloseModal={handleCloseNotesModal}
        />
      </Modal>
      {/* Modal for sort */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={sortModal}
        onOk={handleCloseSortModal}
        onCancel={handleCloseSortModal}
      >
        <SortModal
          handleCloseModal={handleCloseSortModal}
          orderSort={orderSort}
          setOrderSort={setOrderSort}
        />
      </Modal>

      {/* Modal for Filter */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={filterModal}
        onOk={handleCloseFilterModal}
        onCancel={handleCloseFilterModal}
      >
        <FilterModal
          handleCloseModal={handleCloseFilterModal}
          relevanceSort={relevanceSort}
          setRelevanceSort={setRelevanceSort}
        />
      </Modal>

      {/* Modal for relevance */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={releModel}
        onOk={handleCloseRelevanceModel}
        onCancel={handleCloseRelevanceModel}
      >
        <RelevanceModal
          data={relevanceData}
          relaodData={() => getVisitorCompaniesV2(inputs?.event)}
          handleCloseModal={handleCloseRelevanceModel}
        />
      </Modal>

      <Flex
        mt={"30px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        w={"100%"}
      >
        <VStack w={"100%"} textAlign={"left"} alignItems={"flex-start"}>
          <Text fontSize={"sm"} fontWeight={"semibold"}>
            {exhibitorData?.personName}
          </Text>
          <Text fontSize={"sm"} fontWeight={"regular"}>
            {exhibitorData?.companyName}
          </Text>
        </VStack>
        <Button
          w={"40px"}
          h={"40px"}
          borderRadius={"10px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          bg={"gray.100"}
          fontSize={"20px"}
          onClick={() => {
            navigate("/exhibitor/notifications");
          }}
        >
          <span>
            <IoMdNotificationsOutline size={22} />
          </span>
        </Button>
      </Flex>

      <VStack w={"100%"} mt={"30px"} spacing={2} alignItems={"end"}>
        <Flex
          border={"1px solid #E2E8F0"}
          borderRadius={"10px"}
          // padding={"0 15px"}
          w={"100%"}
          alignItems={"center"}
        >
          {/* <TfiWorld size={22} /> */}
          <Select
            className="custom-select-noborder"
            showSearch
            style={{
              width: "100%",
              border: "0px",
              outline: "none",
              height: "50px",
              fontFamily: '"Inter Variable", sans-serif',
            }}
            value={inputs?.event}
            onChange={(e) => {
              setInputs({ ...inputs, event: e });
            }}
            placeholder="Event Name"
            optionFilterProp="children"
            options={[
              ...event?.map((event, index) => ({
                label:
                  event?.eventName +
                  "(" +
                  moment(event?.startDate).format("DD/MMM/YY") +
                  "-" +
                  moment(event?.endDate).format("DD/MMM/YY") +
                  ")",
                value: event?._id,
              })),
            ]}
          />
        </Flex>

        {/* <BiSearch size={22} /> */}
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            getVisitorCompaniesV2(inputs?.event);
          }}
        >
          <Flex
            // padding={"0 15px"}
            w={"100%"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Box
              border={"1px solid #E2E8F0"}
              borderRadius={"10px"}
              bg={"white"}
              w={"100%"}
              mb={2}
            >
              <Input
                placeholder="Company Name"
                type="text"
                _placeholder={{
                  color: "gray.500",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                border={"0px"}
                outline={"none"}
                _focus={{ outline: "none", border: "0px" }}
                h={"50px"}
                w={"100%"}
                px={2}
                borderColor={"gray.300"}
              />
            </Box>
            <Box
              border={"1px solid #E2E8F0"}
              borderRadius={"10px"}
              bg={"white"}
              w={"100%"}
            >
              <Input
                placeholder="Visitor Email"
                type="text"
                _placeholder={{
                  color: "gray.500",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                border={"0px"}
                outline={"none"}
                _focus={{ outline: "none", border: "0px" }}
                h={"50px"}
                w={"100%"}
                px={2}
                borderColor={"gray.300"}
              />
            </Box>
          </Flex>
        </form>
        <Button
          onClick={() => {
            getVisitorCompaniesV2(inputs?.event);
          }}
          colorScheme="transparent"
          variant={"outline"}
          borderColor={"card.borderDark"}
          w={"60px"}
          _hover={{ background: "secondary.light" }}
          h={"30px"}
        >
          <Text fontSize={"sm"} fontWeight={"regular"} color={"secondary.main"}>
            Search
          </Text>
        </Button>
      </VStack>

      <Flex
        // mt={"30px"}
        w={"100%"}
        justifyContent={"space-between"}
        // alignItems={"center"}
        flexDirection={"column"}
      >
        <Text mb={"4px"} fontSize={"md"} fontWeight={"medium"}>
          {companies?.length} visitor companies found
        </Text>
        <Text
          mb={"4px"}
          fontSize={"xs"}
          fontWeight={"regular"}
          color={"text.caption"}
        >
          Click on company name for more details
          <br /> Click on the color icon to change relevance.
        </Text>
        <Flex gap={3} alignItems={"center"} mt={"10px"}>
          {/* <BiFilter
            size={22}
            cursor={"pointer"}
            onClick={handleOpenFilterModal}
            style={{
              background: relevanceSort
                ? relevanceFields.find((s) => s.status == relevanceSort)
                    ?.colorHash
                : "",
              borderRadius: "2px",
              padding: relevanceSort ? "2px" : "",
              color: relevanceSort ? "#FFFFFF" : "",
            }}
          /> */}
          <FilterToggleButton
            buttonRef={filterToggleRef as React.LegacyRef<HTMLButtonElement>}
            buttonText="A-Z"
            onClick={() => {
              if (orderSort == "z-to-a") {
                setOrderSort("a-to-z");
              } else {
                setOrderSort("z-to-a");
              }
            }}
          />
          <Flex
            bg={"white"}
            border={"1px solid #D9D9D9"}
            borderColor={"card.borderLight"}
            borderRadius={"md"}
            height={"25px"}
            alignItems={"center"}
            justifyContent={"center"}
            padding={"0 5px"}
          >
            <Text fontWeight={"regular"} fontSize={"xs"}>
              Relevance
            </Text>
            <RelevanceFilterComponent />
          </Flex>
          <Button variant={"link"} onClick={resetFilters}>
            <Text fontSize={"sm"} fontWeight={"normal"} color={"#006CFF"}>
              Reset
            </Text>
          </Button>
          {/* <BiSort size={22} onClick={handleOpenSortModal} cursor={"pointer"} /> */}
        </Flex>
      </Flex>

      <Box
        w={"100%"}
        mt={"30px"}
        h={"100%"}
        overflowY={"auto"}
        overflowX={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        pb={"100px"}
      >
        {[...companies].map((item, index) => (
          <Box
            p={"15px"}
            cursor={"pointer"}
            _hover={{
              shadow: "md",
            }}
            border={"1px solid #E2E8F0"}
            borderRadius={"15px"}
            shadow={"sm"}
            key={"company_" + index}
            bg={accordinActive == item?.companyName ? "white" : "transparent"}
            // onClick={() => {
            //   if (accordinActive == item?.companyName) {
            //     setAccordinActive("");
            //   } else {
            //     setAccordinActive(item?.companyName);
            //     visitorCompanyListItemClick(item.eventId, item.companyName)
            //   }

            // }}
            onClick={() => {
              if (accordinActive == item?.companyName) {
                setAccordinActive("");
              } else {
                setAccordinActive(item?.companyName);
                visitorCompanyListItemClick(item.eventId, item.companyName);
              }
            }}
          >
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              // onClick={() => {
              //   if (accordinActive == item?.companyName) {
              //     setAccordinActive("");
              //   } else {
              //     setAccordinActive(item?.companyName);
              //     visitorCompanyListItemClick(item.eventId, item.companyName);
              //   }
              // }}
            >
              <Text fontSize={"sm"} fontWeight={"medium"}>
                {item.companyName}
              </Text>
              <Box
                height={18}
                width={18}
                borderRadius={"2px"}
                background={
                  relevanceFields.find(
                    (s) => s.status === item.prequalificationStatus
                  )?.color
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleOpenRelevanceModel();
                  setRelevanceData({
                    prequalified_company_name: item.companyName,
                    prequalified_user_id: item.visitors.map(
                      (i: any) => i.visitorId
                    ),
                    event_id: item.eventId,
                    status: item.prequalificationStatus,
                  });
                }}
              ></Box>
            </Flex>

            <Box
              mt={3}
              overflow={
                accordinActive == item.companyName ? "visible" : "hidden"
              }
              opacity={accordinActive == item.companyName ? 1 : 0}
              height={accordinActive == item.companyName ? "auto" : "0px"}
              transition={"all 0.5s ease-in-out"}
            >
              {/* <Text mb={"14px"} fontSize={"small"} fontWeight={"medium"}>
                Employees :
              </Text> */}
              <VStack w={"100%"} spacing={4}>
                {[...visitors].map((item, index) => (
                  <Box key={"visitor_" + index} w={"100%"} cursor={"default"}>
                    <HStack justifyContent={"space-between"}>
                      <Box>
                        <Text fontSize={"sm"} fontWeight={"medium"} mb={"8px"}>
                          {item?.visitorPersonName}
                        </Text>
                        <Flex>
                          {/* <Text fontSize={"sm"}>12.03.2023 19:03</Text> */}
                        </Flex>
                        <Flex mb={"6px"}>
                          <Text fontSize={"small"} fontWeight={"light"}>
                            {item?.visitorDesignation}
                          </Text>
                        </Flex>
                        <Flex mb={"6px"}>
                          {item?.visitorPersonCity &&
                            item?.visitorPersonCountry && (
                              <Text
                                fontSize={"small"}
                                fontWeight={"light"}
                              >{`${item?.visitorPersonCity}, ${item?.visitorPersonCountry}`}</Text>
                            )}
                          {/* <Text
                            fontSize={"small"}
                            fontWeight={"light"}
                          >{`Bangalore, India`}</Text> */}
                        </Flex>
                        <Flex>
                          <Text fontSize={"small"} fontWeight={"medium"}>
                            Notes :{" "}
                          </Text>
                          {/* <Box
                            width={"50px"}
                            whiteSpace={"nowrap"}
                            display={"inline-block"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                          >
                            <Text fontSize={"small"} fontWeight={"light"}>This is a sample note</Text>
                          </Box>
                          <Text fontSize={"sm"}>...</Text> */}
                          <Link
                            color={"card.borderBlue"}
                            onClick={() => {
                              handleOpenNotesModal();
                              setNotesData((prev: any) => ({
                                ...prev,
                                forUser: item?._id,
                                companyName: item?.visitorCompanyName,
                              }));
                            }}
                          >
                            <Text color={"card.borderBlue"} fontSize={"sm"}>
                              &nbsp; View/Add
                            </Text>
                          </Link>
                        </Flex>
                      </Box>

                      <Flex>
                        <VStack cursor={"pointer"}>
                          <Button colorScheme="transparent">
                            <span>
                              <MdCalendarToday
                                onClick={() => {
                                  console.log("meetingdata...", meetingData);

                                  if (!exhibitorData.personPhoneNumber)
                                    return error(
                                      "Please provide your contact number in the profile page"
                                    );

                                  if (
                                    userPlan.closedMeetingsCount ==
                                    userPlan.closedMeetingsLimit
                                  ) {
                                    return error(
                                      "You have reached the subscription limit of closed meetings. Please renew your subscription."
                                    );
                                  }

                                  if (meetingData[item?.visitorCompanyName]) {
                                    let meetingObj =
                                      meetingData[item?.visitorCompanyName];

                                    console.log("meetingObj...", meetingObj);

                                    if (
                                      isNewMeetingAllowed(
                                        user.user === meetingObj.requesterId
                                          ? meetingObj.requesterStatus
                                          : meetingObj.responderStatus
                                      )
                                    ) {
                                      handleOpenMeetModal();
                                      setMeetResponder(item);
                                    } else {
                                      handleOpenMeetConfirmModal();
                                      setCurrentMeet(meetingObj._id);
                                    }
                                  } else {
                                    handleOpenMeetModal();
                                    setMeetResponder(item);
                                  }
                                }}
                                size={22}
                                color={"#F5793D"}
                              />
                            </span>
                          </Button>
                          <Text fontWeight={"regular"} fontSize={"xs"}>
                            Meet
                          </Text>
                        </VStack>
                        <VStack cursor={"pointer"}>
                          <Button colorScheme="transparent">
                            <span>
                              <MdChat
                                onClick={() => {
                                  navigate(
                                    "/exhibitor/chat?user=" +
                                      item?.userId +
                                      "&event=" +
                                      details.event +
                                      "&m=" +
                                      item?._id,
                                    {
                                      state: {
                                        visitor: item,
                                      },
                                    }
                                  );
                                }}
                                size={22}
                                color={"#F5793D"}
                              />
                            </span>
                          </Button>
                          <Text fontWeight={"regular"} fontSize={"xs"}>
                            Chat
                          </Text>
                        </VStack>
                      </Flex>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ExhibitorHome;
